import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Velocity Wheels Araç Kiralama Hakkında
			</title>
			<meta name={"description"} content={"Velocity Wheels Araç Kiralama net bir misyonla kuruldu: araç kiralamayı sadece bir gereklilik değil, aynı zamanda keyifli bir seyahat deneyiminin önemli bir parçası haline getirmek."} />
			<meta property={"og:title"} content={"Hakkımızda | Velocity Wheels Araç Kiralama Hakkında"} />
			<meta property={"og:description"} content={"Velocity Wheels Araç Kiralama net bir misyonla kuruldu: araç kiralamayı sadece bir gereklilik değil, aynı zamanda keyifli bir seyahat deneyiminin önemli bir parçası haline getirmek."} />
			<meta property={"og:image"} content={"https://gemrassa.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://gemrassa.com/img/2389246.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gemrassa.com/img/2389246.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gemrassa.com/img/2389246.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Hakkımızda
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					Velocity Wheels Araç Kiralama net bir misyonla kuruldu: araç kiralamayı sadece bir gereklilik değil, aynı zamanda keyifli bir seyahat deneyiminin önemli bir parçası haline getirmek. Hizmetimiz, müşteri memnuniyetine olan bağlılığımız ve açık yol tutkumuzla şekillenmektedir.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://gemrassa.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Araç Kiralamaya Yaklaşımımız
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Velocity Wheels olarak, ister iş ister tatil amaçlı olsun, araç kiralamanın genellikle seyahatin önemli bir parçası olduğunu biliyoruz. Kolaylık, konfor ve güvenilirlik sağlamak için hizmetlerimizi geliştirdik ve her yolculuğu unutulmaz bir deneyime dönüştürdük.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://gemrassa.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Velocity Wheels'i Öne Çıkaran Nedir?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Müşteri Odaklı Hizmet - Yaklaşımımız her zaman müşteri önceliklidir. Bizimle iletişime geçtiğiniz andan itibaren ekibimiz, araç kiralama deneyiminizi sorunsuz ve sorunsuz hale getirmeye kendini adamıştır.
							<br />
							<br />
							Kalite ve Çeşitlilik - Filomuz, her biri güvenlik ve konfor için titizlikle korunan bütçe dostu modellerden lüks araçlara kadar uzanmaktadır.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://gemrassa.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Sadece Araba Kiralamanın Ötesinde
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Özel Deneyimler - İster şehir içinde gezinmek için ister manzaralı bir yolculuğa çıkmak için bir araç arıyor olun, özel seyahat ihtiyaçlarınıza göre uyarlanmış araçlar ve paketler sunuyoruz.
							<br />
							<br />
							Mükemmellik Taahhüdü - Ekibimiz, sektör trendleri ve müşteri geri bildirimleri ile güncel kalarak araç kiralama deneyimini yükseltmek için sürekli çaba göstermektedir.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://gemrassa.com/img/8.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Hakkımızda Daha Fazlasını Keşfedin
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Hizmetlerimiz, filomuz ve sunduğumuz benzersiz kiralama deneyimi hakkında daha fazla bilgi için:
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/contacts"
							padding="0 0 0 0"
							background="0"
							color="--primary"
							type="link"
							text-decoration-line="initial"
						>
							Bize Ulaşın   &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});